import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from "react-redux"
import Popover from '@mui/material/Popover';


const CounterClick = ({ maxAllowed, onDecrement, onIncrement, value, symbol }) => {
  const { isDesktop, isTablet, isMobile } = useSelector((state) => state.status);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1500);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Box className="add-to-cart-btn-after">
          <Box className="add-remove">
            <Button className="remove-icon" onClick={onDecrement}>
              <RemoveIcon />
            </Button>
            <Typography className="item-count-text">
              {value}  {!isMobile && (<spna>in bag</spna>)}
            </Typography>
            {maxAllowed > 0 && maxAllowed <= value ? (
              <Button className="add-icon" onClick={handleClick}>
                <AddIcon />
              </Button>
            ) : (
              <Button className="add-icon" onClick={onIncrement}>
                <AddIcon />
              </Button>
            )}
          </Box>
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Typography sx={{ p: 2 }}>সর্বোচ্চ {maxAllowed} {symbol} ক্রয় করা যাবে</Typography>
        </Popover>
      </Box>

    </>
  );
};

export default CounterClick;
