import React from 'react';
import {
    CardContent,
    Typography,
    Card,
    CardMedia,
    Grid,
} from '@mui/material';
import noResult from '../../assets/images/no-result.svg';

const NoItemFound = () => {
    return (
        <Grid container
            justifyContent="center"
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                xl={6}
                sx={{ marginTop: '10vh' }}
            >
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" color="Green" textAlign='center'>
                            No Product Found
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        image={noResult}
                        alt="result-not-found"
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default NoItemFound;