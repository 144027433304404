import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
// footer import
import LogoImage from '../../assets/images/Logo-shop.png';
import facebook from '../../assets/images/app-icon/Facebook.png';
import insta from '../../assets/images/app-icon/Instagram.png';
import twitter from '../../assets/images/app-icon/twitter.png';
import google from '../../assets/images/app-icon/google-w.png';
import apple from '../../assets/images/app-icon/apple-w.png';
import visa from '../../assets/images/app-icon/visa.png';
import american from '../../assets/images/app-icon/american.png';
import nagad from '../../assets/images/app-icon/nagad.png';
import bkash from '../../assets/images/app-icon/bkash.png';
import master from '../../assets/images/app-icon/mastercard.png';
import '../../assets/css/layout/footer.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const CustomFooter = () => {
  const mydate = new Date();
  const fullYear = mydate.getFullYear();
  return (
    <Box className="footer-section-main">
      <Grid
        container
        className="footer-section"
        spacing={{ xs: 3, md: 2, xl: 0 }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <img src={LogoImage} alt="no-logo-found" className="footer-logo" />
          <Box className="logo-content">
            <Typography variant="h5" className="header-title">
              Who we are
            </Typography>
            <Typography>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis
              similique repellat assumenda ipsam quos iusto soluta in
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="contact-info">
          <Box>
            <Typography variant="h5" className="header-title">
              Contact Info
            </Typography>
            <Typography className="text-with-icon">
              <LocalPhoneIcon />:01847165168,{'\n'}01847165165
            </Typography>
            <Typography className="text-with-icon">
              <EmailIcon />: itrading.ic@gmail.com
            </Typography>
            <Typography className="text-with-icon">
              <LocationOnIcon />: House No : 8 Road No : 4,{'\n'}
              Nasirabad Housing Society,{'\n'}
              Chattogram
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="about-us-sec">
          <Box className="about-section">
            <Typography variant="h5" className="header-title">
              About us
            </Typography>
            <Link to="#" className="abt-link">
              <Typography>About us</Typography>
            </Link>
            <Link to="/privacy" className="abt-link">
              <Typography>Privacy policy</Typography>
            </Link>
            <Box className="social-media">
              <a href="https://www.facebook.com/itrading20" target="_blank" rel="noopener noreferrer">
                <img
                  src={facebook}
                  className="facebook"
                  alt="no-icon-found"
                  width="25"
                />
              </a>
              <a href="https://www.facebook.com/itrading20" target="_blank" rel="noopener noreferrer">
                <img
                  src={insta}
                  className="insta"
                  alt="no-icon-found"
                  width="25"
                />
              </a>
              <a href="https://www.facebook.com/itrading20" target="_blank" rel="noopener noreferrer">
                <img
                  src={twitter}
                  className="twitter"
                  alt="no-icon-found"
                  width="25"
                />
              </a>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className="social-media-sec">
          <Box className="app-section">
            <Typography variant="h5" className="header-title">
              Find us
            </Typography>
            <Box className="app-store">
              <a
                href="https://play.google.com/store/apps/details?id=com.itradingwsale"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={google} alt="no-logo" width="170" />
              </a>{' '}
              <br />
              <a
                className="apple-img-link"
                href="https://play.google.com/store/apps/details?id=com.itradingwsale"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="apple-img"
                  src={apple}
                  alt="no-logo"
                  width="170"
                />
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className="payment-copy-section">
        <Box className="payment-section">
          <Typography variant="body2">
            Pay us with :
            <img
              src={visa}
              alt="no-pp-found"
              className="pay-img"
              width="30px"
            />
            <img
              src={master}
              alt="no-pp-found"
              className="pay-img"
              width="25px"
            />
            <img
              src={american}
              alt="no-pp-found"
              className="pay-img"
              width="15px"
            />
            <img
              src={bkash}
              alt="no-pp-found"
              className="pay-img"
              width="15px"
            />
            <img
              src={nagad}
              alt="no-pp-found"
              className="pay-img"
              width="15px"
            />
          </Typography>
        </Box>
        <Typography className="copy-title" variant="body2">
          ©{fullYear} All Rights Reserverd iTrading
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomFooter;
