import React, { useState, useEffect } from 'react';
import axios from "axios";
import Products from '../Components/products/list/product-list';
import ProductSkeletonList from '../Components/products/list/skeleton-list';
import { useDispatch, useSelector } from "react-redux"
import { SEARCH_URL, API_KEY } from '../common/baseUrl'
import { resetSearchProductInfo, saveSearchProductInfo } from '../store/reducers/productReducer'
import NoItemFound from '../Components/no-item-found/no-item-found'
let pageNo = 1;

const SearchProduct = () => {
    const dispatch = useDispatch();
    //const [isFetching, setIsFetching] = useInfiniteScroll(fetchData);
    const [allLoaded, setAllLoaded] = useState(false);
    const [itemNotfound, setItemNotfound] = useState(false);
    const { searchProductInfo, searchText } = useSelector((state) => state.product);
    const { outlet_id } = useSelector((state) => state.app);

    useEffect(() => {
        pageNo = 1;
        window.scrollTo(0, 0)
        dispatch(resetSearchProductInfo());
        setAllLoaded(false);
        fetchData();
    }, [searchText]);

    const fetchData = () => {
        const isConnectionAvailable = window.navigator.onLine;
        if (isConnectionAvailable && searchText !== '') {
            axios.post(SEARCH_URL,
                {
                    str: searchText,
                    outlet_id: outlet_id,
                    pageNo: pageNo
                },
                { timeout: 3000 })
                .then((response) => {

                    dispatch(saveSearchProductInfo(response.data.searchProductInfo));
                    setAllLoaded(true);
                })
                .catch((error) => {

                });
            setTimeout(() => {
                isConnectionLost();
            }, 20000);

        } else {

        }
    }

    const isConnectionLost = () => {

    }

    return (
        <div>
            {allLoaded && searchProductInfo.length < 1 &&
                <NoItemFound />
            }
            <Products productList={searchProductInfo} />
            {!allLoaded && searchProductInfo.length < 1 && <ProductSkeletonList />}
        </div>
    );
};

export default SearchProduct;