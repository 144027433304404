import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalContent from './modal-content';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    //p: 4,
    '::-webkit-scrollbar': {
        width: '1px',
    },

    overflowX: 'hidden',
    width: { xs: '80%', md: '80%', lg: '70%', xl: '60%' },
    height: { xs: '0%', md: '90%', lg: '80%', xl: '70%' },
    zIndex: '+15',
};

export default function ProductModal({ open, setOpen, exact_url, data }) {
   
    const handleClose = () => { setOpen(false); console.log(exact_url); window.history.replaceState("", "", exact_url); }
    
    return (
        <div className="product-modal-main-sec">
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box>
                    <Button className="sm-screen-btn" onClick={handleClose}>
                        <CloseIcon
                            sx={{
                                boxShadow: 3,
                                fontSize: 26,
                                p: 1,
                                borderRadius: '50%',
                                backgroundColor: 'white',
                                color: 'black',
                                position: 'fixed',
                                color: '#009e7f',
                                top: '8%',
                                right: '8%',
                                display: { xs: 'none', md: 'block' },
                            }}
                        />
                    </Button>
                    <Box sx={style}>
                        {open && <ModalContent data={data} />}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
