import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import '../../assets/css/layout/cart-drawer.css';
import { useCart } from '../../contexts/cart/use-cart';
import DrawerItemData from './drawer-item-data';

export default function SwipeableCartDrawer() {
  const { isOpen, toggleCart } = useCart();
  const list = () => (
    <Box
      sx={{ width: '400px' }}
      role="presentation"
      onKeyDown={toggleCart}
      className="side-cart-main-section"
    >
      <DrawerItemData />
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor="right"
          open={isOpen}
          onClose={toggleCart}
          onOpen={toggleCart}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
