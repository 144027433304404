import { Box, Typography } from '@mui/material';
import React from 'react';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import '../../assets/css/layout/items-price.css';
import { useCart } from '../../contexts/cart/use-cart';
import SwipeableCartDrawer from '../cart-drawer';
import { CURRENCY } from '../../utils/constant';

const ItemsCount = () => {
    const { isOpen, cartItemsCount, toggleCart, calculatePrice } = useCart();

    return (
        <>
            {isOpen && <SwipeableCartDrawer />}
            <Box className="cart-count product-cart" onClick={toggleCart}>
                <Box className="item-count">
                    <LocalMallIcon className="item-icon" />
                    <Typography className="count-number">
                        {cartItemsCount} <span>items</span>
                    </Typography>
                </Box>
                <Box className="money-count">{CURRENCY} {calculatePrice()}</Box>
            </Box>
        </>
    );
};

export default ItemsCount;
