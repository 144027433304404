import { configureStore } from '@reduxjs/toolkit';

import appReducer from './reducers/appReducer';
import productReducer from './reducers/productReducer';
import cartReducer from './reducers/cartReducer';
import authReducer from './reducers/authReducer';
import statusReducer from './reducers/statusReducer';
import subTypeItemsReducer from './reducers/subTypeItemsReducer';

export default configureStore({
  reducer: {
    app: appReducer,
    product: productReducer,
    cart: cartReducer,
    auth: authReducer,
    status: statusReducer,
    subTypeItems: subTypeItemsReducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //     getDefaultMiddleware({
  //         serializableCheck: false,
  //     }),
});
