import { Box } from '@mui/material';
import React from 'react';
import CartItems from '../layouts/cart-drawer/cart-items';
import '../assets/css/layout/cart-drawer.css';

const MobileCart = () => {
    return (
        <Box sx={{ background: '#fff' }}>
            <CartItems />
        </Box>
    );
};

export default MobileCart;
