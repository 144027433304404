import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import Products from '../Components/products/list/product-list';
import ProductSkeletonList from '../Components/products/list/skeleton-list';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux"
import { SUBTYPE_ITEM_URL } from '../common/baseUrl'
import { selectedType, showWebFooter, hideWebFooter } from '../store/reducers/appReducer'
import { resetSubTypeItems, saveSubTypeItems } from '../store/reducers/productReducer'
import { setLoadingMore } from '../store/reducers/subTypeItemsReducer';
import useInfiniteScroll from "../utils/useInfiniteScroll";
import NoItemFound from '../Components/no-item-found/no-item-found'
import InfiniteScroll from "react-infinite-scroll-component"
let stop = 0;
let pageNo = 1;

const SubCategory = () => {
    const dispatch = useDispatch();
    //const [isFetching, setIsFetching] = useInfiniteScroll(fetchData);
    const [isFetching, setIsFetching] = useState(true);
    const { outlet_id, isLoaded } = useSelector((state) => state.app);
    const { subTypeProductInfo } = useSelector((state) => state.product);
    const { loadingMore } = useSelector((state) => state.subTypeItems);
    const { type_id, subtype_id } = useParams();
    //const [loadingMore, setLoadingMore] = useState(false);
    const [allLoaded, setAllLoaded] = useState(false);
    const [itemNotfound, setItemNotfound] = useState(false);
    const [itemNo, setItemNo] = useState(28);

    function fetchData() {
        let itemURL = SUBTYPE_ITEM_URL + subtype_id + '/' + outlet_id + '/' + pageNo;
        axios.get(itemURL)
            .then((response) => {
                setTimeout(() => {
                    dispatch(saveSubTypeItems(response.data.subtypeProductInfo));
                    setItemNo(response.data.record_per_fatch);
                    if (response.data.subtypeProductInfo.length == response.data.record_per_fatch) {
                        dispatch(hideWebFooter());
                    } else {
                        dispatch(showWebFooter());
                        setAllLoaded(true);
                        setIsFetching(false);
                    }
                }, 500);
                pageNo = pageNo + 1;
            })
            .catch((error) => {
                console.log(error);
                //setIsFetching(false);
            })
    };

    useEffect(() => {
        pageNo = 1;
        window.scrollTo(0, 0)
        dispatch(resetSubTypeItems());
        setAllLoaded(false);
        setIsFetching(true);
        fetchData();
    }, [subtype_id])

    useEffect(() => {
        if (isLoaded) {
            let typeInfo = {
                type_id: type_id,
                subtype_id: subtype_id,
                filterSubType: true
            }
            dispatch(selectedType(typeInfo));
        }
    }, [isLoaded]);

    return (
        <div>
            {allLoaded && subTypeProductInfo.length < 1 &&
                <NoItemFound />
            }
            {!allLoaded && subTypeProductInfo.length < 1 &&
                <ProductSkeletonList />
            }
            <InfiniteScroll
                dataLength={subTypeProductInfo.length}
                next={() => fetchData()}
                hasMore={isFetching}
                loader={<Box sx={{ flexDirection: "column", display: 'flex', justifyContent: 'center', minHeight: "15vh", alignItems: "center" }}>
                    <CircularProgress />
                    <Typography sx={{ py: 0, color: "blue" }}>Loading...</Typography>
                </Box>}
            // endMessage={
            //     <p style={{ textAlign: "center" }}>
            //         <b>Yay! You have seen it all</b>
            //     </p>
            // }
            >
                <Products productList={subTypeProductInfo} />
            </InfiniteScroll>
            {/* {isFetching && !allLoaded &&
                <Box sx={{ flexDirection: "column", display: 'flex', justifyContent: 'center', minHeight: "15vh", alignItems: "center" }}>
                    <CircularProgress />
                    <Typography sx={{ py: 1, color: "blue" }}>Loading...</Typography>
                </Box>
            } */}
        </div>
    );
};

export default SubCategory;