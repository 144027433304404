import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useCart } from '../../contexts/cart/use-cart';
import { IMAGE_URL } from '../../common/baseUrl';
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from 'react-router-dom';

const MobileOrderDetails = () => {
  const { orderDetails } = useSelector((state) => state.auth);
  const location = useLocation();
  const data = location.state;
  
  return (
    <>
      <Box className="cart-content-sec" sx={{ pb: 5 }}>
        <Grid container className="cart-with-product" spacing={2}>
          {orderDetails.map((orderItem) => (
            <>
              <Grid
                container
                item
                xs={12}
                xl={6}
                spacing={2}
                key={orderItem.product_id}
              >
                <Grid item xs={2} className="product-img-sec">
                  <img
                    className="product-img"
                    src={IMAGE_URL + orderItem.type_id + '/' + orderItem.app_pic1}
                    alt=""
                  />
                </Grid>
                <Grid item xs={10} className="title-sec">
                  <Box className="title-sec-inner">
                    <Typography className="product-title">
                      {orderItem.product_title_beng} {orderItem.size}
                    </Typography>
                    <Box className="product-price-sec">
                      <Typography className="unit-price">
                        ৳ {orderItem.sale_price}
                      </Typography>
                      <Typography className="product-unit">
                        <ClearIcon />
                        <span>{orderItem.quantity}</span>
                      </Typography>
                      <Typography className="total-price">
                        ৳ {orderItem.sale_price * orderItem.quantity}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          ))}
          <Grid item xs={12} className="payable-sec">
            <Box className="subtotal-sec">
              <Box>
                <Typography className="sub-total-text">
                  Subtotal Amount
                </Typography>
                <Typography className="sub-total-text">
                  Delivery Charge
                </Typography>
              </Box>
              <Box>
                <Typography className="sub-total-text">{data.sub_total}</Typography>
                <Typography className="sub-total-text">{data.delivery_charge}</Typography>
              </Box>
            </Box>
            <Divider />
            <Box className="total-sec">
              <Typography className="total-sec-text">Total Amount</Typography>
              <Typography className="total-sec-text"> ৳ {data.total_price}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MobileOrderDetails;
