import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ModalContent from './modal-content';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SmallScreenModal({ open, setOpen, exact_url, data }) {
    const handleClose = () => {
        setOpen(false);
    };
   // const handleClose = () => { setOpen(false); console.log(exact_url); window.history.replaceState("", "", exact_url); }
    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Button className="sm-screen-btn" onClick={handleClose}>
                    <CloseIcon
                        sx={{
                            boxShadow: 3,
                            fontSize: 21,
                            padding: '5px',
                            borderRadius: '50%',
                            backgroundColor: 'white',
                            color: '#009e7f',
                            position: 'fixed',
                            zIndex: 21,
                            right: '25px',
                            top: '25px',
                            display: { xs: 'block', md: 'none' },
                        }}
                    />
                </Button>
                {open && <ModalContent data={data} />}
            </Dialog>
        </div>
    );
}
