import React from 'react';
import { Slide } from 'react-slideshow-image';
//import 'react-slideshow-image/dist/styles.css'
//import Slider from './components/Slider';
import 'react-slideshow-image/dist/styles.css';
import { IMAGE_URL } from "../../common/baseUrl";
import { Zoom } from 'react-slideshow-image';
import './slider.css';

const AppSlider = ({ images }) => {
  const slideImages = [
    IMAGE_URL + 'slider/' + images[0].slider_pic,
    IMAGE_URL + 'slider/' + images[1].slider_pic,
    IMAGE_URL + 'slider/' + images[2].slider_pic,
    IMAGE_URL + 'slider/' + images[3].slider_pic,
  ]

  const slideImages2 = [
    {
      url: IMAGE_URL + 'slider/' + images[0].slider_pic,

    },
    {
      url: IMAGE_URL + 'slider/' + images[1].slider_pic,

    },
    {
      url: IMAGE_URL + 'slider/' + images[2].slider_pic,

    },
    {
      url: IMAGE_URL + 'slider/' + images[3].slider_pic,

    }
  ];

  const properties = {
    duration: 2000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false,
    onChange: (oldIndex, newIndex) => {
      //console.log(`slide transition from ${oldIndex} to ${newIndex}`);
    }
  }

  return (
    //  <Slider slides={sliders} autoPlay={3} />
    <div className="slide-container">
      <Zoom scale={0.4}>
        {
          slideImages.map((each, index) => <img key={index} style={{ width: "100%" }} src={each} />)
        }
      </Zoom>     
    </div>
  );
};


export default AppSlider;