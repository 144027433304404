import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, Card } from '@mui/material';

const SkeletonCard = () => {
    return (
        <>
            <Card className="product-single-card product-card">
                <Box className="card-content">
                    <Skeleton variant="rectangular" width={200} height={180} />
                    <Skeleton width="80%" />
                    <Skeleton width="60%" />
                    <Skeleton width="50%" />
                </Box>
            </Card>
        </>
    );
};

export default SkeletonCard;
