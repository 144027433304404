import { makeStyles } from 'tss-react/mui';
import { blueGrey } from "@mui/material/colors";
import { blue } from "@mui/material/colors";

export const useStyles = makeStyles()((theme) => ({
  toolbar: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
  },
  logo: {
    color: "white",
  },
  navlist: {
    minWidth: "250px",
    maxWidth: "300px",
  },
  ulAvater: {
    backgroundColor: blue["A200"],
    color: "white",
  },
  navAvatar: {
    width: "35px",
    height: "35px",
  },

  //wrapper of main contianer
  wrapper: {
    minHeight: "100vh",
    height: "auto",
    background: "#efefef",
    paddingLeft: '295px !important',
    paddingRight: '0px !important',
    marginTop: "60px",
    padding: theme.spacing(2, 2, 0, 40),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(2, 2),
      paddingLeft: '0px !important',
      marginTop: "45px",
    }
  },

  cswrapper: {
    minHeight: "100vh",
    height: "auto",
    background: "#efefef",
    marginTop: "60px",
    padding: theme.spacing(2, 2, 0, 40),
    [theme.breakpoints.up("xs")]: {
      padding: theme.spacing(2, 2),
      marginTop: "95px",
      marginBottom:"140px"
    }
  },

  //Side nav
  drawerPaper: {
    width: "300px",
    marginTop: "65px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "0px",
    },
  },
  navlinks: {
    color: blueGrey["A400"],
    "& :hover , &:hover div": {
      color: blue["A200"],
    },
    " & div": {
      color: blueGrey["A400"],
    },
  },
  activeNavlinks: {
    color: blue["A700"],
    "& div": {
      color: blue["A700"],
    },
  },
  navButton: {
    width: " 100%",
    textTransform: "capitalize",
  },
}));
