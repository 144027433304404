import { Badge, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/layout/view-cart-footer.css';
import { useCart } from '../../contexts/cart/use-cart';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector } from "react-redux"
const ViewCartFooter = () => {
    const { deliveryChargeMax, deliveryChargeMin, mimimunAmount, deliveryChargeMSG } = useSelector((state) => state.app);
    const { isOpen, cartItemsCount, toggleCart, calculatePrice } = useCart();
    const subTotal = calculatePrice();
    let deliveryCharge = 0;
    if (parseFloat(subTotal) < parseFloat(mimimunAmount)) {
        deliveryCharge = parseFloat(deliveryChargeMax).toFixed(2);
    } else {
        deliveryCharge = parseFloat(deliveryChargeMin).toFixed(2);
    }

    const Payable = (parseFloat(subTotal) + parseFloat(deliveryCharge)).toFixed(2);

    return (
        <>
            <Box className="view-cart-footer product-cart">
                {parseFloat(subTotal) < parseFloat(mimimunAmount) && (
                    <Box className="shipping-offer-text">
                        <Typography>{deliveryChargeMSG}</Typography>
                    </Box>)}
                <Box className="amount-count-sec">
                    <Box sx={{ p: '0 1em' }}>
                        <Typography className="amount-common sub">
                            <span>Subtotal: </span> <span>{calculatePrice()}</span>
                        </Typography>
                        <Typography className="amount-common ship">
                            <span>Delivery charge: </span> <span>{deliveryCharge}</span>
                        </Typography>
                    </Box>
                </Box>
                <Box className="cart-item-below">
                    <Box className="cart-item-below-inner">
                        <Link to="/" className="cart-back-link-btn">
                            <HomeIcon /> <span>Home</span>
                        </Link>
                        <Badge badgeContent={cartItemsCount} color="secondary">
                            <Link to="/checkout" className="home-btn-link">
                                <Button variant="contained" className="place-order-common-btn">
                                    Checkout
                                </Button>
                            </Link>
                        </Badge>
                        <Typography className="item-and-cost">{Payable}</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ViewCartFooter;