import React, { useEffect } from 'react';
import Products from '../Components/products/list/product-list';
import ProductSkeletonList from '../Components/products/list/skeleton-list';
import { useDispatch, useSelector } from "react-redux"
import AppSlider from '../Components/slider/AppSlider';
import SliderForMobile from '../Components/slider/SliderForMobile';
import { IMAGE_URL } from '../common/baseUrl';
import { selectedType, toggleOpen } from '../store/reducers/appReducer'
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Typography,
  Breadcrumbs
} from '@mui/material';
import GrainIcon from '@mui/icons-material/Grain';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sliderInfo, popularItemsInfo, product_type, typeId, specialCategories } = useSelector((state) => state.app);
  const { isDesktop, isTablet, isMobile } = useSelector((state) => state.status);

  const handleClickOnType = (type_id) => {
    window.scrollTo(0, 0)
    if (typeId !== type_id) {
      let typeInfo = {
        type_id: type_id,
        subtype_id: 9900,
        filterSubType: true
      }
      dispatch(selectedType(typeInfo));
    } else {
      dispatch(toggleOpen());
    }
    navigate(`/category/${type_id}`);
  };

  //   useEffect(() => {
  //     window.onpopstate = e => {
  //       e.preventDefault();
  //      }
  // }, []);

  return (
    <div>
      <div className='slider-category'>
        {isMobile ?
          <>
            {sliderInfo.length > 0 && <SliderForMobile images={sliderInfo} />}
          </>
          :
          <>
            {sliderInfo.length > 0 && <AppSlider images={sliderInfo} />}

          </>
        }
        <div
          role="presentation"
          // onClick={handleClick}
          className="breadcrumbs-container"
        >
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: 1 }}>
            <Typography
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.primary"
              className="current-item"
            >
              <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Shop by Offer Categories
            </Typography>
          </Breadcrumbs>
        </div>
        <Grid container spacing={2} className="category-main-page">
          {specialCategories.map((typeinfo, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              xl={4}
              // key={product.product_id}
              onClick={() => handleClickOnType(typeinfo.type_id)}

              sx={{ cursor: 'pointer' }}
              key={i}
            >
              <Card className='card-sec'>
                <CardMedia
                  component="img"
                  image={IMAGE_URL + 'banner/' + typeinfo.mobile_banner}
                  alt="no-category-img-found"
                />
                <Typography className="cat-view-more-btn-text">
                  View{' '}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
        <div
          role="presentation"
          // onClick={handleClick}
          className="breadcrumbs-container"
        >
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: 2 }}>
            <Typography
              sx={{ display: 'flex', alignItems: 'center' }}
              color="text.primary"
              className="current-item"
            >
              <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Shop by Categories
            </Typography>
          </Breadcrumbs>
        </div>
        <Grid container spacing={2} className="category-main-page">
          {product_type.map((typeinfo, i) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              xl={2}
              // key={product.product_id}
              onClick={() => handleClickOnType(typeinfo.type_id)}

              sx={{ cursor: 'pointer' }}
              key={i}
            >
              <Card className='card-sec'>
                <CardMedia
                  component="img"
                  image={IMAGE_URL + 'banner/' + typeinfo.mobile_banner_retail}
                  alt="no-category-img-found"
                />
                <Typography className="cat-view-more-btn-text">
                  View{' '}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <div
        role="presentation"
        // onClick={handleClick}
        className="breadcrumbs-container"
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: 2 }}>
          <Typography
            sx={{ display: 'flex', alignItems: 'center' }}
            color="text.primary"
            className="current-item"
          >
            <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Popular Product
          </Typography>
        </Breadcrumbs>
      </div>
      <Products productList={popularItemsInfo} />
      {popularItemsInfo.length < 1 && <ProductSkeletonList />}
    </div>
  )
};

export default Dashboard;
