import { createSlice } from "@reduxjs/toolkit";
const authReducer = createSlice({
    name: "auth",
    initialState: {
        isFormLoginBtn: false,
        isFormChkOut: false,
        showLoginModal: false,
        customer_id: '',
        isLogin: false,
        phoneNo: '',
        custName: '',
        custAdress: '',
        alternativeMobileNo: '',
        refkey: '',
        myOrderInfo: [],
        orderDetails: []
    },
    reducers: {
        showLoginModal: (state) => {
            return {
                ...state,
                isFormLoginBtn: true,
                isFormChkOut: false,
                showLoginModal: true,
            }
        },
        LoginFromChkOut: (state) => {
            return {
                ...state,
                isFormLoginBtn: false,
                isFormChkOut: true,
                showLoginModal: true,
            }
        },
        clickOnFooterChkOutBtn: (state) => {
            state.isFormLoginBtn = false;
            state.isFormChkOut = true;
            state.showLoginModal = false;
        },
        closeLoginModal: (state) => {
            return {
                ...state,
                isFormLoginBtn: false,
                showLoginModal: false,
            }
        },
        saveUserInfo: (state, action) => {
            return {
                ...state,
                isFormLoginBtn: false,
                showLoginModal: false,
                isLogin: true,
                customer_id: action.payload.customer_id,
                phoneNo: action.payload.mobile,
                custName: action.payload.name,
                custAdress: action.payload.address,
                alternativeMobileNo: action.payload.altContact
            }
        },
        saveRefKey: (state, action) => {
            return {
                ...state,
                refkey: action.item
            }
        },
        saveMyOrderInfo: (state, action) => {
            state.myOrderInfo = [...state.myOrderInfo, ...action.payload];
        },
        saveOrderDetails: (state, action) => {
            state.orderDetails = [...state.orderDetails, ...action.payload];
        },
        resetMyOrderInfo: (state) => {
            state.myOrderInfo = [];
        },
        resetOrderDetails: (state) => {
            state.orderDetails = [];
        },
        resetLoginInfo: (state) => {
            return {
                ...state,
                isLogin: false,
                customer_id: '',
                phoneNo: '',
                custName: '',
                custAdress: '',
                alternativeMobileNo: '',
                refkey: '',
                myOrderInfo: [],
                orderDetails: []
            }
        },
    }
});

export const { showLoginModal, LoginFromChkOut, closeLoginModal, saveUserInfo,
    saveRefKey, saveMyOrderInfo, saveOrderDetails, resetMyOrderInfo,
    clickOnFooterChkOutBtn, resetOrderDetails, resetLoginInfo } = authReducer.actions;

export default authReducer.reducer;