import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import moment from "moment";

const OrderInfo = ({ order, getInfo, activeId }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box
          className={`order-info-box ${activeId === order.r_order_id ? 'active' : ''}`}
          onClick={() => getInfo(order.r_order_id)}
        >
          <Typography className="order-info">
            <span className="info-name">Order Id: </span>
            {order.r_order_id}
          </Typography>
          <Typography className="order-info">
            <span className="info-name">Net payable: </span>
            {order.total_price}
          </Typography>
          <Typography className="order-info">
            <span className="info-name">Date: </span>
            {moment(order.order_date).format("DD/MM/YYYY")}
          </Typography>
          <Typography className="order-info">
            <span className="info-name">Status: </span>
            {order.status}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderInfo;
