import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import ItemsCount from './items-price'
import HomeFooter from './items-price/dashboard-footer'
import CartFooter from './items-price/view-cart-footer'
import Header from '../layouts/header/header';
import Sidenav from '../layouts/sidebar/Sidenav';
import { useStyles } from '../assets/styles/core.style';
import CustomFooter from './footer/footer';
import { useEffect } from 'react';
import axios from 'axios';
import { START_URL, OUTLET_URL } from '../common/baseUrl';
import { useDispatch, useSelector } from "react-redux"
import { saveAppsData, saveOutletInfo, savePopularItemsInfo, showWebFooter } from '../store/reducers/appReducer'
import { deviceIsMobile, deviceIsTablet, deviceIsDesktop } from '../store/reducers/statusReducer'

const Layout = () => {
  const { startfatching, isOuletSelected, outlet_id, showFooter } = useSelector((state) => state.app);
  const { isDesktop, isTablet, isMobile } = useSelector((state) => state.status);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCartPage, setIsCartPage] = useState(false);
  const [isCheckOutPage, setIsCheckOutPage] = useState(false);
  const [isHome, setIsHome] = useState(true);

  const handleDrawerOpen = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const [state, setState] = useState(true);
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const handleWindowWidth = () => {

    if (window.innerWidth < 601) {
      dispatch(deviceIsMobile());
    }

    if (window.innerWidth < 1201 && window.innerWidth > 600) {
      dispatch(deviceIsTablet());
    }

    if (window.innerWidth > 1200) {
      dispatch(deviceIsDesktop());
    }
  }

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('resize', handleWindowWidth)
    }
  }, [windowWidth])

  const hanldeCustomFooter = () => {
    if (location.pathname === '/cart-page' ||
      location.pathname === '/login' ||
      location.pathname === '/checkout' ||
      location.pathname === '/my-order' ||
      location.pathname === '/my-order-details') {
      setState(false);
    } else {
      dispatch(showWebFooter());
      setState(true);
    }
  };

  const hanldeMobileFooter = () => {

    if (location.pathname === '/cart-page') {
      setIsCartPage(true);
      setIsCheckOutPage(false);
      setIsHome(false);
    } else if (location.pathname === '/checkout') {
      setIsCartPage(false);
      setIsCheckOutPage(false);
      setIsHome(false);
    } else {
      setIsCartPage(false);
      setIsCheckOutPage(false);
      setIsHome(true);
    }
  };

  useEffect(() => {
    hanldeCustomFooter();
    hanldeMobileFooter();
  }, [location]);

  useEffect(() => {
    //sendPushNotification();
    
    handleWindowWidth();
    axios.get(START_URL)
      .then((res) => {
        dispatch(saveAppsData(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const sendPushNotification = async () => {
    const FIREBASE_API_KEY = "AAAAnVi7s3Q:APA91bEvoV53eF6fWLzaxcOmDtn2ov70gKIy1G06I38xOhtLZO1LgpYc950aFtSAbOzk_K8BkSX-UwTInSejXJZOZKu6qB4Pfm5HYdQ_eszPAX5K0Sh2w3Vuu0j3_hn33pMs8x13IJzD"
    const message = {
      registration_ids: [
        "cTuzEfYsS-2k1PZWvpqoHq:APA91bFIgLBeq3tHAO9_AgD-IlfdKlUxL7P9jyXb-c0m5pQUQUo6OvWCRmwB_9zcRII6OF4TZtWDpuO9UHQgmdjtP5EoUlhRhD7SWWCIstPTgQ4TRSDKMRNAceuY5r4-W9ykwRIvxBJE",
      ],
      notification: {
        title: "india vs south africa test",
        body: "IND chose to bat",
        vibrate: 1,
        sound: 1,
        show_in_foreground: true,
        priority: "high",
        content_available: true,
      },
      data: {
        title: "india vs south africa test",
        body: "IND chose to bat",
        score: 50,
        wicket: 1,
      },
    }

    let headers = new Headers({
      "Content-Type": "application/json",
      Authorization: "key=" + FIREBASE_API_KEY,
    })

    let response = await fetch("https://fcm.googleapis.com/fcm/send", {
      method: "POST",
      headers,
      body: JSON.stringify(message),
    })
    response = await response.json()
    console.log(response)
  }

  return (
    <div>
      <Header handleDrawerOpen={handleDrawerOpen} />
      <Sidenav
        mobileOpen={mobileOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />

      {isMobile ?
        <>
          {/* {isHome && <HomeFooter />} */}
          {isCartPage ? <CartFooter /> : <HomeFooter />}
        </>
        :
        <ItemsCount />
      }
      <Box className={classes.wrapper}>
        <Box sx={{ minHeight: '70vh' }}>
          <Outlet></Outlet>
        </Box>
        {state && showFooter && <CustomFooter />}
      </Box>
    </div>
  );
};

export default Layout;