import { createSlice } from "@reduxjs/toolkit";
const statusReducer = createSlice({
    name: "status",
    initialState: {
        isDesktop: false,
        isTablet: false,
        isMobile: false,
        isInternetAvailable: true,
        loadingMoreDashboard: false,
        allLoadedDashboard: false
    },
    reducers: {
        saveInternetInfo: (state, action) => {
            return {
                ...state,
                isInternetAvailable: action.item
            }
        },
        saveLoadingMoreDashboardStatus: (state, action) => {
            return {
                ...state,
                loadingMoreDashboard: action.item
            }
        },
        saveLoadedDashboardStatus: (state, action) => {
            return {
                ...state,
                allLoadedDashboard: action.item
            }
        },
        deviceIsMobile: (state) => {
            state.isDesktop = false;
            state.isTablet = false;
            state.isMobile = true;
        },
        deviceIsTablet: (state) => {
            state.isDesktop = false;
            state.isTablet = true;
            state.isMobile = false;
        },
        deviceIsDesktop: (state) => {
            state.isDesktop = true;
            state.isTablet = false;
            state.isMobile = false;
        },
        resetStatus: (state) => {
            return {
                ...state,
                isDesktop: false,
                isTablet: false,
                isMobile: false,
                isInternetAvailable: true,
                loadingMoreDashboard: false,
                allLoadedDashboard: false
            }
        }
    }
});

export const { deviceIsMobile, deviceIsTablet, deviceIsDesktop, saveInternetInfo, saveLoadingMoreDashboardStatus, saveLoadedDashboardStatus, resetStatus } = statusReducer.actions;

export default statusReducer.reducer;