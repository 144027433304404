import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import '../../assets/css/layout/cart-drawer.css';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useNavigate } from 'react-router-dom';
import CartItems from './cart-items';
import { useCart } from '../../contexts/cart/use-cart';
import { useDispatch, useSelector } from "react-redux"
import { LoginFromChkOut } from '../../store/reducers/authReducer'
import { CURRENCY } from '../../utils/constant';
const DrawerItemData = () => {
  const dispatch = useDispatch();
  const { isLogin } = useSelector((state) => state.auth);
  const { deliveryChargeMSG } = useSelector((state) => state.app);
  const { cartItemsCount, toggleCart, calculatePrice } = useCart();
  const navigate = useNavigate();

  const handleCheckout = () => {
    toggleCart();
    if (isLogin) {
      navigate('/checkout');
    } else {
      dispatch(LoginFromChkOut());
    }
  };

  return (
    <Box className="cart-main-container">
      <Box className="cart-top-sec">
        <Box className="cart-total-item-sec">
          <IconButton
            size="normal"
            edge="end"
            aria-label="cart-icon"
            aria-haspopup="false"
            color="inherit"
            className="cart-top-icon"
          >
            <ShoppingBagIcon />
          </IconButton>
          <Box className="total-item-count">
            {cartItemsCount > 1 ? (
              <Typography className="top-item-text">
                {cartItemsCount} items
              </Typography>
            ) : (
              <Typography className="top-item-text">
                {cartItemsCount} item
              </Typography>
            )}
          </Box>
        </Box>
        <Box className="close-sec">
          <Button className="close-sec-btn" onClick={toggleCart}>
            Close
          </Button>
        </Box>
      </Box>
      <Divider />
      <CartItems />
      <Box className="bottom-section-main">
        <Box className="bottom-section-container">
          <Typography className="shipping-offer">
            {deliveryChargeMSG}
          </Typography>
          <Box className="checkout-box">
            <Button className="checkout-text" onClick={handleCheckout}>
              <Typography className="checkout-text">Checkout</Typography>
            </Button>
            <Button className="checkout-btn">{CURRENCY} {calculatePrice()}</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DrawerItemData;
