import { Badge, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/layout/moble-footer.css';
import HomeIcon from '@mui/icons-material/Home';
import { useCart } from '../../contexts/cart/use-cart';
const MobileFooter = () => {
    const { isOpen, cartItemsCount, toggleCart, calculatePrice } = useCart();
    return (
        <Box className="mobile-footer product-cart">
            <Link to="/cart-page" className="home-btn-link">
                <Badge badgeContent={cartItemsCount} color="secondary">
                    <Button variant="contained" className="place-order-common-btn">
                        View cart
                    </Button>
                </Badge>
            </Link>
            <Typography className="item-and-cost">{calculatePrice()}</Typography>
            <Link to="/" className="cart-back-link-btn">
                <HomeIcon /> <span>Home</span>
            </Link>
        </Box>
    );
};

export default MobileFooter;