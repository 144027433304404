import { createSlice } from "@reduxjs/toolkit";

const cartReducer = createSlice({
    name: "cart",
    initialState: {
        cartItems: [],
        itemsCount: 0,
        totalPrice: 0,
        discount: 0,
    },
    reducers: {
        addToCart: (state, action) => {
            state.cartItems.push(action.payload);
            //const pressOnItem = state.cartItems[action.payload.id];
            state.itemsCount = state.itemsCount + 1;
            state.totalPrice = parseFloat(state.totalPrice) + parseFloat(action.payload.price);
            let bagInfo = {
                totalPrice: state.totalPrice,
                itemsCount: state.itemsCount,
                cartItems: state.cartItems
            }

            //storeData(bagInfo);

            // if (pressOnItem) {

            // } else {
            //     state.cartItems = { ...state.cartItems, [action.payload.id]: action.payload };
            //     state.itemsCount = state.itemsCount + 1;
            //     state.totalPrice = parseFloat(state.totalPrice) + parseFloat(action.payload.price);
            //     let bagInfo = {
            //         totalPrice: state.totalPrice,
            //         itemsCount: state.itemsCount,
            //         cartItems: state.cartItems
            //     }

            //     storeData(bagInfo);
            // }

        },
        removeItem: (state, action) => {

            const selectedCartItem = state.cartItems[action.payload];

            if (selectedCartItem) {
                state.itemsCount = state.itemsCount - 1;
                state.totalPrice = parseFloat(state.totalPrice) - (parseFloat(selectedCartItem.price) * parseFloat(selectedCartItem.quantity));
                state.cartItems = { ...state.cartItems };
                delete state.cartItems[action.payload];

                let bagInfo = {
                    totalPrice: state.totalPrice,
                    itemsCount: state.itemsCount,
                    cartItems: state.cartItems
                }

                //storeData(bagInfo);
            }
        },
        decreaseQuantity: (state, action) => {

            const selectedCartItem = state.cartItems[action.payload];

            if (selectedCartItem) {
                // already have the item in the cart    
                const currentQty = selectedCartItem.quantity;
                if (currentQty > 1) {
                    state.cartItems = {
                        ...state.cartItems,
                        [action.payload]: { ...selectedCartItem, quantity: selectedCartItem.quantity - 1 },
                    };
                    state.totalPrice = parseFloat(state.totalPrice) - parseFloat(selectedCartItem.price);

                } else {
                    state.itemsCount = state.itemsCount - 1;
                    state.totalPrice = parseFloat(state.totalPrice) - (parseFloat(selectedCartItem.price) * parseFloat(selectedCartItem.quantity));
                    state.cartItems = { ...state.cartItems };
                    delete state.cartItems[action.payload];
                }
            }

            let bagInfo = {
                totalPrice: state.totalPrice,
                itemsCount: state.itemsCount,
                cartItems: state.cartItems
            }

            //storeData(bagInfo);
        },
        increaseQuantity: (state, action) => {

            const increaseCartItem = state.cartItems[action.payload];

            if (increaseCartItem) {
                // already have the item in the cart           
                state.cartItems = {
                    ...state.cartItems,
                    [action.payload]: { ...increaseCartItem, quantity: increaseCartItem.quantity + 1 },
                };
                state.totalPrice = parseFloat(state.totalPrice) + parseFloat(increaseCartItem.price);
            }

            let bagInfo = {
                totalPrice: state.totalPrice,
                itemsCount: state.itemsCount,
                cartItems: state.cartItems
            }

            //storeData(bagInfo);

        },
        addBagInfo: (state, action) => {
            // state.cartItems = action.payload.cartItems;
            // state.itemsCount = parseFloat(action.payload.itemsCount);
            // state.totalPrice = parseFloat(action.payload.totalPrice);
        },
        emptyCart: (state) => {
            state.cartItems = [];
            state.itemsCount = 0;
            state.totalPrice = 0;
            state.discount = 0;
        },
    },
});

export const { addToCart, removeItem, increaseQuantity, decreaseQuantity, addBagInfo, emptyCart } = cartReducer.actions;

export default cartReducer.reducer;
