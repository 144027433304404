import { Box } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const CounterInputBox = ({ maxAllowed, onDecrement, onIncrement, value, symbol }) => {
    const inputRef = useRef();
    useEffect(() => {
       
       
       // inputRef.current?.focus();
    }, []);

    const [state, setState] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1500);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div>
            <Box className="modal-add-to-cart">
                <Box item xs={1} className="card-btn-box">
                    <Box className="card-btn-sec">
                        <RemoveIcon className="card-remove-btn" onClick={onDecrement} />
                        <input
                            defaultValue={1}
                            type="number"
                            className="card-input-btn"
                            //ref={inputRef}
                            readonly={true}
                            value={value}
                        />
                        {maxAllowed > 0 && maxAllowed <= value ? (
                            <AddIcon className="card-add-btn" aria-describedby={id} variant="contained" onClick={handleClick} />
                        ) : (
                            <AddIcon className="card-add-btn" onClick={onIncrement} />
                        )}

                    </Box>
                </Box>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>সর্বোচ্চ {maxAllowed} {symbol} ক্রয় করা যাবে.</Typography>
            </Popover>
        </div>
    );
};

export default CounterInputBox;
