import { createSlice } from "@reduxjs/toolkit";
const subTypeItemsReducer = createSlice({
    name: "subTypeItems",
    initialState: {
        subTypeItemsInfo: [],
        visible: true,
        loadingMore: false,
        allLoaded: true,
        itemNotfound: false,
        isLoading: true,
        isLoaded: false,
        connectionLost: false
    },
    reducers: {
        saveSubTypeItemsInfo: (state, action) => {
            return {
                ...state,
                subTypeItemsInfo: [...state.subTypeItemsInfo, ...action.payload],
            }
        },
        setVisible: (state, action) => {
            return {
                ...state,
                visible: action.payload,
            }
        },
        setLoadingMore: (state, action) => {
            return {
                ...state,
                loadingMore: action.payload,
            }
        },
        setAllLoaded: (state, action) => {
            return {
                ...state,
                allLoaded: action.payload,
            }
        },
        setItemNotfound: (state, action) => {
            return {
                ...state,
                itemNotfound: action.payload,
            }
        },
        setIsLoading: (state, action) => {
            return {
                ...state,
                isLoading: action.payload,
                visible: action.payload,
            }
        },
        setConnectionLost: (state, action) => {
            return {
                ...state,
                connectionLost: action.payload,
            }
        },
        resetSubTypeProductInfo: (state) => {
            return {
                ...state,
                subTypeItemsInfo: [],
                visible: true,
                loadingMore: false,
                allLoaded: true,
                itemNotfound: false,
                isLoading: true,
                isLoaded: false,
                connectionLost: false,
            }
        }
    }
});

export const { saveSubTypeItemsInfo, setVisible, setLoadingMore, setAllLoaded, setIsLoading, setItemNotfound,

    setConnectionLost, resetSubTypeProductInfo } = subTypeItemsReducer.actions;

export default subTypeItemsReducer.reducer;