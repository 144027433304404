import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import '../../assets/css/features/login-register-form.css';
import LoginForm from './login-form';
import { useDispatch, useSelector } from "react-redux"
import { showLoginModal, closeLoginModal } from '../../store/reducers/authReducer'

export default function LoginWithOtpModal() {
  const dispatch = useDispatch();
  const showLoginModal = useSelector((state) => state.auth.showLoginModal);
  const handleClose = () => dispatch(closeLoginModal());
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLoginModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <>
          <LoginForm />
        </>
      </Modal>
    </div>
  );
}
