import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { CartProvider } from '../src/contexts/cart/use-cart';
import Layout from './layouts';
import Dashboard from './pages/Dashboard';
import Category from './pages/Category';
import SubCategory from './pages/SubCategory';
import ProductDetails from './pages/product-details';
import SearchProduct from './pages/SearchProduct';
import LoginForm from './features/authentication-form-new/login-form';
import Checkout from './pages/Checkout';
import MyOrderInfo from './pages/my-order';
import MobileCart from './pages/MobileCart';
import OrderInfoMobile from './pages/my-order/OrderInfoMobile';
import MobileOrderDetails from './pages/my-order/mobile-details';
import PrivacyPolicy from './pages/Privacy-policy';

function App() {

  return (
    <CartProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/category/:type_id" element={<Category />} />
            <Route path="/subcategory/:type_id/:subtype_id" element={<SubCategory />} />
            <Route path="/product-details/:product_id" element={<ProductDetails />} />
            <Route path="/search" element={<SearchProduct />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/my-order" element={<MyOrderInfo />} />
            <Route path="/cart-page" element={<MobileCart />} />
            <Route path="/my-order-info" element={<OrderInfoMobile />} />
            <Route path="/my-order-details" element={<MobileOrderDetails />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            {/* Using path="*"" means "match anything", so this route acts like a catch-all for URLs that we don't have explicit routes for. */}
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </CartProvider>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div >
  );
}

export default App;
