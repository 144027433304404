import React from 'react';
import { Box } from '@mui/material';
import SkeletonCard from '../card/skeleton-card';

export const ProductSkeleton = () => {
    return (
        <>
            <Box className="product_card_main_sec">
                {[...Array(30)].map((x, i) => (
                    <SkeletonCard />
                ))}
            </Box>
        </>
    );
};

export default ProductSkeleton;
