import { Box } from '@mui/material';
import React from 'react';
import ProductCard from '../card/product-card';
const Products = ({ productList }) => {
    return (
        <>
            <Box className="product_card_main_sec">
                {productList.map((product, index) => (
                    <ProductCard
                        key={product.product_id}
                        product={product}
                        index={index}
                    />
                ))}
            </Box>
        </>
    );
};

export default Products;