import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import emptyCart from '../../assets/images/empty-cart.jpg';
import '../../assets/css/layout/cart-drawer.css';
import { useCart } from '../../contexts/cart/use-cart';
import { IMAGE_URL } from '../../common/baseUrl';
import { useDispatch, useSelector } from "react-redux"

const OrderDetails = (selectedOrder) => {
  const { items, cartItemsCount } = useCart();
  const { orderDetails } = useSelector((state) => state.auth);
  
  return (
    <>
      <Box className="cart-content-sec">
        <Grid container className="cart-with-product" spacing={2}>
          {orderDetails.map((orderItem) => (
            <>
              <Grid
                container
                item
                xs={12}
                xl={6}
                spacing={2}
                key={orderItem.product_id}
              >
                <Grid item xs={2} className="product-img-sec">
                  <img
                    className="product-img"
                    src={IMAGE_URL + orderItem.type_id + '/' + orderItem.app_pic1}
                    alt=""
                  />
                </Grid>
                <Grid item xs={10} className="title-sec">
                  <Box className="title-sec-inner">
                    <Typography className="product-title">
                      {orderItem.product_title_beng} {orderItem.size}
                    </Typography>
                    <Box className="product-price-sec">
                      <Typography className="unit-price">
                        ৳ {orderItem.sale_price}
                      </Typography>
                      <Typography className="product-unit">
                        <ClearIcon />
                        <span>{orderItem.quantity}</span>
                      </Typography>
                      <Typography className="total-price">
                        ৳ {orderItem.sale_price * orderItem.quantity}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          ))}
          <Grid item xs={12} className="payable-sec">
            <Box className="subtotal-sec">
              <Box>
                <Typography className="sub-total-text">
                  Subtotal Amount
                </Typography>
                <Typography className="sub-total-text">
                  Delivery Charge
                </Typography>
              </Box>
              <Box>
                <Typography className="sub-total-text">{selectedOrder.selectedOrder.sub_total}</Typography>
                <Typography className="sub-total-text">{selectedOrder.selectedOrder.delivery_charge}</Typography>
              </Box>
            </Box>
            <Divider />
            <Box className="total-sec">
              <Typography className="total-sec-text">Total Amount</Typography>
              <Typography className="total-sec-text"> ৳ {selectedOrder.selectedOrder.total_price}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrderDetails;
