export const baseURL = 'https://www.itrading.com.bd/api/v1/getWebsiteInfo/';
export const PRODUCTINFO_URL = 'https://www.itrading.com.bd/api/retail/v1/productInformation/';
export const subTypeURL = 'https://www.itrading.com.bd/api/retail/v1/subCategoryProduct/';
export const SEND_OTP_URL = 'https://www.itrading.com.bd/api/retail/v1/sendSms/';
export const REGISTER_CUSTOMER_URL = 'https://www.itrading.com.bd/api/retail/v1/registerCustomer/';
export const API_KEY = 'ba8dd74c58444d83';
export const IMAGE_URL = "https://www.itrading.com.bd/pos/assets/product_image/";
export const PRODUCT_SEARCH_URL = 'https://www.itrading.com.bd/api/searchItem/';
export const OUTLET_URL = 'https://www.goodrelations.com.bd/api/getOutletInfo';
export const START_URL = 'https://www.itrading.com.bd/api/retail/v1/getDashboardData/1';
export const SUBTYPE_ITEM_URL = 'https://www.itrading.com.bd/api/retail/v1/subTypeItemsInfo/';
export const CUSTOM_TYPE_URL = 'https://www.itrading.com.bd/api/retail/v1/customTypeItems/';
export const SEARCH_URL = 'https://www.itrading.com.bd/api/retail/v1/searchProduct';
export const PLACE_ORDER_URL = 'https://www.itrading.com.bd/api/retail/v1/placeOrder';
export const MY_ORDER_URL = 'https://www.itrading.com.bd/api/retail/v1/getMyOrder/';
export const ORDER_DETAILS_URL = 'https://www.itrading.com.bd/api/retail/v1/getOrderDetails/';

export const STORAGE_URL = 'https://www.itrading.com.bd/pos/assets/product_image/';