import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItemButton, Typography, Divider, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { IMAGE_URL } from '../../common/baseUrl';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AccountCircle from '@mui/icons-material/AccountCircle';
import myOrderIcon from '../../assets/images/my-order.png';
// import '../../assets/css/layout/sidenav-sidenav-data.css';
import { useDispatch, useSelector } from "react-redux"
import { selectedType, toggleOpen } from '../../store/reducers/appReducer'
import { resetLoginInfo } from '../../store/reducers/authReducer'

const drawerWidth = 310;

export default function SidenavData({ handleDrawerClose }) {
  const dispatch = useDispatch();
  const { open, typeId, subTypeId, sidebarData } = useSelector((state) => state.app);
  const { isLogin, custName, phoneNo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  //const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  //const [clicked, setClicked] = useState(0);
  const [click, setClick] = useState(false);

  const handleClickOnType = (type_id) => {
    if (typeId !== type_id) {
      let typeInfo = {
        type_id: type_id,
        subtype_id: 9900,
        filterSubType: true
      }
      dispatch(selectedType(typeInfo));
      window.scrollTo(0, 0)
      //setClicked(type_id);
    } else {
      dispatch(toggleOpen());
    }
  };

  const text = {
    fontSize: 15,
  };

  // selected item on nav tree
  const [selectedItem, setSelectedItem] = useState('');
  const [selectSubItem, setSelectSubItem] = useState('');

  const handleLogin = () => {
    navigate('/login');
    handleDrawerClose();
  };

  const handleLogout = () => {
    localStorage.removeItem("@user");
    dispatch(resetLoginInfo());
    handleDrawerClose();
  };

  const handleShowMyOrder = () => {
    navigate('/my-order-info');
    handleDrawerClose();
  };

  const handleEditProfile = () => {
    handleDrawerClose();
    navigate('/update-user');
  };

  return (
    <List>
      <Box sx={{ display: { md: 'none' } }}>
        {!isLogin ?
          <Box className="small-screen-login-sec">
            <Button className="small-screen-login-btn" onClick={handleLogin}>Login</Button>
          </Box>
          :
          <Box>
            <Box className="small-screen-user-sec">
              <AccountCircle className="user-circle" />
              <Box className="user-info">
                <Typography className="user-name">{custName}</Typography>
                <Typography className="user--mobile">{phoneNo}</Typography>
              </Box>
            </Box>
            <Box className="order-info">
              <img src={myOrderIcon} width="30" alt="order-img-n-found" />
              <Typography className="my-order-text" onClick={handleShowMyOrder}>My Order</Typography>
            </Box>
            <Box className="order-info">
              <LogoutIcon />
              <Typography className="my-order-text" onClick={handleLogout}>Logout</Typography>
            </Box>
          </Box>
        }
        <Divider />
      </Box>
      {sidebarData.map((sideItem, i) => (
        <Box key={i}>
          <ListItemButton
            onClick={() => {
              handleDrawerClose();
              handleClickOnType(sideItem.type_id);
              setSelectedItem(sideItem.product_type);
            }}
            component={Link}
            to={`/category/${sideItem.type_id}`}
            className={` list-item-main ${typeId === sideItem.type_id ? 'active-item-bg' : ''
              }`}
          >
            <ListItemAvatar sx={{ minWidth: '38px' }}>
              <Avatar
                alt={sideItem.product_type}
                src={IMAGE_URL + 'banner' + '/' + sideItem.type_icon}
                sx={{ height: '21px', width: '21px' }}
              />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ style: text }}
              primary={sideItem.product_type}
              className={`${(typeId === sideItem.type_id) ? 'active-item' : ''
                }`}
            />
            {sideItem.sub_menu.length > 0 ? (
              <>
                {typeId === sideItem.type_id ? (
                  <>
                    {open ? (
                      <ExpandLess
                        className={`${typeId === sideItem.type_id
                          ? 'active-item-icon'
                          : ''
                          }`}
                      />
                    ) : (
                      <ExpandMore />
                    )}
                  </>
                ) : (
                  <>{close ? <ExpandLess /> : <ExpandMore />}</>
                )}
              </>
            ) : null}
          </ListItemButton>

          {typeId === sideItem.type_id ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              {sideItem.sub_menu.map((subItem, i) => (
                <List component="div" disablePadding key={i}>
                  <ListItemButton
                    onClick={() => {
                      setClick(!click);
                      handleDrawerClose();
                      let typeInfo = {
                        type_id: typeId,
                        subtype_id: subItem.subtype_id,
                        filterSubType: false
                      }
                      dispatch(selectedType(typeInfo));
                    }}

                    sx={{ pl: 4 }}
                    component={Link}
                    to={`/subcategory/${typeId}/${subItem.subtype_id}`}
                    className="collapse-list-items"
                  >
                    <ListItemIcon>
                      <ArrowRightIcon className='arrow-right-width'/>
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ style: text }}
                      primary={subItem.subproduct_type}
                      className={`${subTypeId === subItem.subtype_id
                        ? 'active-item'
                        : ''
                        }`}
                    />
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          ) : (
            <Collapse in={false} timeout="auto" unmountOnExit>
              {sideItem.sub_menu.map((subItem, i) => (
                <List component="div" disablePadding key={i}>
                  <ListItemButton
                    onClick={() => {
                      setClick(!click);
                    }}
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`/subcategory/${subItem.subtype_id}`}
                  >
                    <ListItemIcon>
                      <ArrowRightIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ style: text }}
                      primary={subItem.subproduct_type}
                    />
                  </ListItemButton>
                </List>
              ))}
            </Collapse>
          )}
        </Box>
      ))}
    </List>
  );
}
