import { Box, Card, CardMedia, Chip, Button, Typography } from '@mui/material';
import React from 'react';
import { IMAGE_URL } from '../../../common/baseUrl';
import bImage from '../../image/blanck.jpg';
import { cartAnimation } from '../../../utils/cart-animation';
import ProductModal from '../modal/product-modal';
import SmallScreenModal from '../modal/full-screen-modal';
import { useState } from 'react';
import CounterClick from '../counter/counter-click'
import { useCart } from '../../../contexts/cart/use-cart';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useSelector } from "react-redux"
import { useNavigate, useLocation } from 'react-router-dom';
import Popover from '@mui/material/Popover';

const ProductCard = ({ product, index }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { addItem, removeItem, getItem, isInCart } = useCart();
    const { isDesktop, isTablet, isMobile } = useSelector((state) => state.status);
    const [url, setUrl] = useState(location.pathname);

    let image_url = '';
    if (product.web_pic1 !== '') {
        image_url = IMAGE_URL + product.type_id + '/' + product.web_pic1;
    } else {
        image_url = bImage;
    }

    const handleAddClick = (e) => {
        e.stopPropagation();
        addItem(product);
        if (!isInCart(product.product_id)) {
            cartAnimation(e);
        }
    };

    const handleRemoveClick = (e) => {
        e.stopPropagation();
        removeItem(product);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        navigate(`/product-details/${product.product_id}`);
        //setOpen(true); window.history.replaceState("", "", '/product-details/' + product.product_id);
    };
    const handleClose = () => { setOpen(false); window.history.replaceState("", "", url); }

    return (
        <>
            <Card className="product-single-card product-card">
                <CardMedia
                    component="img"
                    alt="green iguana"
                    // height="180"
                    image={image_url}
                    loading="lazy"
                    className="product-card-img product-image"
                    onClick={handleOpen}
                />

                <Box className="card-content" onClick={handleOpen}>
                    {/* <Typography className="common-card-title card-ent-title">
                        {product.product_title_eng}
                    </Typography>
                    <Typography className=" common-card-title card-ban-title">
                        {product.product_title_beng}
                    </Typography> */}
                    <Typography className=" common-card-title card-ban-title">
                        <span>{product.product_title_beng}</span>
                        <span> {product.size}</span>
                    </Typography>
                    {/* <Typography className="product-size">{product.size}</Typography> */}
                    {product.is_available == 1 ? (
                        <Typography className="product-price">
                            <span className="sales-price">{product.sale_price}</span>
                            {parseFloat(product.discount) > 0 && parseFloat(product.max_retail_price) > 0 && (
                                <span className="origin-price">{product.max_retail_price}</span>
                            )}
                        </Typography>
                    )
                        :
                        <Typography className="product-price" sx={{ color: 'white' }}>
                            <span>{product.sale_price}</span>
                        </Typography>
                    }

                </Box>
                {/* <CardAddToCart
                    handleAddToCart={handleAddToCart}
                    handleOpen={handleOpen}
                /> */}
                {!isInCart(product.product_id) ? (
                    <>
                        {product.is_available == 1 ? (
                            <Box sx={{ textAlign: 'center' }} onClick={handleAddClick}>
                                <Button
                                    variant="outlined"
                                    className="add-to-cart-btn"
                                >
                                    <ShoppingBasketIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
                                    Add to bag
                                    {/* Out of Stock */}
                                </Button>
                            </Box>
                        )
                            :
                            (
                                <Box sx={{ textAlign: 'center' }}>
                                    <Button
                                        variant="outlined"
                                        className="out-of-stock-btn"
                                    >
                                        Out Of Stock
                                    </Button>
                                </Box>
                            )
                        }

                    </>
                ) : (
                    <CounterClick
                        maxAllowed={product.max_allowed}
                        value={getItem(product.product_id).quantity}
                        onDecrement={handleRemoveClick}
                        onIncrement={handleAddClick}
                        symbol={product.symbol}
                    />
                )}
                {parseFloat(product.discount) > 0 && parseFloat(product.discount) > 0 && product.is_available > 0 && (
                    <>
                        {
                            product.less_type === 'percent' ?
                                <Chip className="discount-percent" label={`${product.discount} % off`} size="small" />
                                :
                                <Chip className="discount-percent" label={`৳ ${product.discount} off`} size="small" />
                        }
                    </>
                )}

            </Card>
            {isMobile ? (
                <SmallScreenModal
                    setOpen={setOpen}
                    open={open}
                    exact_url={url}
                    data={product}
                />
            ) : (
                <ProductModal
                    setOpen={setOpen}
                    open={open}
                    exact_url={url}
                    data={product}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

export default ProductCard;
