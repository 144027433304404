import { createSlice } from "@reduxjs/toolkit";
const productReducer = createSlice({
    name: "product",
    initialState: {
        subTypeProductInfo: [],
        searchProductInfo: [],
        offerItems: [],
        customGroupItems: [],
        dayWiseGroupItems: [],
        singleProductInfo: [],
        relatedProductInfo: [],
        searchText: ''
    },
    reducers: {
        resetSubTypeItems: (state) => {
            state.subTypeProductInfo = [];
        },
        saveSubTypeItems: (state, action) => {
            state.subTypeProductInfo = [...state.subTypeProductInfo, ...action.payload];
        },
        resetSearchText: (state) => {
            state.searchText = '';
        },
        saveSearchText: (state, action) => {
            state.searchText = action.payload;
        },
        resetSearchProductInfo: (state) => {
            state.searchProductInfo = [];
        },
        saveSearchProductInfo: (state, action) => {
            console.log(action.payload);
            state.searchProductInfo = [...state.searchProductInfo, ...action.payload];
        },
        resetOfferProductInfo: (state) => {
            state.offerItems = [];
        },
        saveOfferProductInfo: (state, action) => {
            state.offerItems = [...state.offerItems, ...action.payload];
        },
        resetCustomGroupItems: (state) => {
            state.customGroupItems = [];
        },
        saveCustomGroupItems: (state, action) => {
            state.customGroupItems = [...state.customGroupItems, ...action.payload];
        },
        resetDayWiseGroupItems: (state) => {
            state.dayWiseGroupItems = [];
        },
        saveDayWiseGroupItems: (state, action) => {
            state.dayWiseGroupItems = [...state.dayWiseGroupItems, ...action.payload];
        },
        resetItemsInfo: (state) => {
            state.singleProductInfo = [];
            state.relatedProductInfo = [];
        },
        saveItemsInfo: (state, action) => {
            if (action.payload.productInfo.length > 0) {
                state.singleProductInfo = action.payload.productInfo[0];
            }
            state.relatedProductInfo = action.payload.relatedProductInfo;
        },
        emptyProductInfo: (state) => {
            state.subTypeProductInfo = [];
            state.searchProductInfo = [];
            state.offerItems = [];
            state.customGroupItems = [];
            state.dayWiseGroupItems = [];
            state.singleProductInfo = [];
            state.relatedProductInfo = [];
        }
    }
});

export const { resetSubTypeItems, saveSubTypeItems, resetSearchText, saveSearchText,
    resetSearchProductInfo, saveSearchProductInfo, resetOfferProductInfo, saveOfferProductInfo,
    resetCustomGroupItems, saveCustomGroupItems, resetDayWiseGroupItems,
    saveDayWiseGroupItems, emptyProductInfo, resetItemsInfo, saveItemsInfo } = productReducer.actions;

export default productReducer.reducer;