import * as React from 'react';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  InputBase,
  MenuItem,
  Menu,
  Toolbar,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import iFamilyLogo from '../../assets/images/Logo-shop.png';
import { Link } from 'react-router-dom';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LoginIcon from '@mui/icons-material/Login';
import '../../assets/css/layout/header.css';
import { useState } from 'react';
import { useEffect } from 'react';
import LoginWithOtpModal from '../../features/authentication-form-new/login-modal';
import { useCart } from '../../contexts/cart/use-cart';
import { useDispatch, useSelector } from "react-redux"
import { showLoginModal, saveUserInfo, resetLoginInfo } from '../../store/reducers/authReducer'
import { useNavigate } from 'react-router-dom';
import { resetSearchText, saveSearchText } from '../../store/reducers/productReducer'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#c9c9c999',
  '&:hover': {
    backgroundColor: '#c9c9c9bd',
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(3),
    width: '40%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export default function Header({ handleDrawerOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLogin, custName, phoneNo } = useSelector((state) => state.auth);
  const { isDesktop, isTablet, isMobile } = useSelector((state) => state.status);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  useEffect(() => {
    let CustInfo = JSON.parse(localStorage.getItem("@user"));
    if (CustInfo !== null) {
      let customerInfo = {
        customer_id: CustInfo.customer_id,
        name: CustInfo.name,
        address: CustInfo.address,
        mobile: CustInfo.mobile,
        altContact: CustInfo.altContact,
      }
      dispatch(saveUserInfo(customerInfo));
    }
  }, []);

  // search function start
  /*   const [searchText, setSearchText] = useState('');
  const [users, setUser] = useState([]);
  const [myuser, setMyUsers] = useState([]);

  console.log(users, 'user');
  console.log(myuser, 'my yser fitler');

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/users')
      .then((res) => res.json())
      .then((data) => setUser(data));
    const filterUsers = users.filter((user) =>
      user.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setMyUsers(filterUsers);
  }, [searchText]); */

  // search function  end

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleShowMyOrder = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate('/my-order');
  };

  const handleLogout = () => {
    localStorage.removeItem("@user");
    dispatch(resetLoginInfo());
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handleShowMyOrder}>My Order</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  // drawer section code
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const timerRef = React.useRef(null);

  const searchProduct = (text) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (text !== '') {
        if (text.match(/[a-zA-Z0-9]/i)) {
          if (text.length > 2) {
            dispatch(saveSearchText(text));
          }
        } else {
          if (text.length > 1) {
            dispatch(saveSearchText(text));
          }
        }
        navigate('/search');
      } else {
        dispatch(resetSearchText());
      }
    }, 800);
  };


  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" className="header-app-bar">
          <Toolbar>
            {isTablet || isMobile ?
              <IconButton
                size="large"
                edge="start"
                //color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={handleDrawerOpen}
              >
                <MenuIcon className="menu-icon" />
              </IconButton>
              : null}
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Link to="/">
                <img src={iFamilyLogo} alt="" />
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Search className="cw-search-icon-property">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={(e) =>
                  searchProduct(e.target.value)
                }
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {/* <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                className="profile-icon"
                onClick={toggleCart}
              >
                <AddShoppingCartIcon />
              </IconButton> */}
              {!isLogin ?
                <>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    //onClick={handleProfileMenuOpen}
                    color="inherit"
                    className="profile-icon"
                  >
                    <LoginIcon />
                  </IconButton>
                  <Button className="header-login-btn" onClick={() => { dispatch(showLoginModal()); }}>
                    Login
                  </Button>
                </>
                :
                <>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    //onClick={handleProfileMenuOpen}
                    color="inherit"
                    className="profile-icon"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Button className="header-login-btn" onClick={handleProfileMenuOpen}>
                    {custName}
                  </Button>
                </>
              }
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <LoginWithOtpModal />
    </>
  );
}
