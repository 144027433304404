import { useState, useEffect } from 'react';

const useInfiniteScroll = (callback) => {
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        callback();
    }, [isFetching]);

    function handleScroll() {
        if ((
            window.innerHeight + 400 +
            Math.max(
                window.pageYOffset,
                window.scrollY,
                document.documentElement.scrollTop,
                document.body.scrollTop
            ) !==
            document.documentElement.offsetHeight + 400
        ) || isFetching) {
            return;
        }

        // if (((window.innerHeight + document.documentElement.scrollTop) + 300) !== (document.documentElement.offsetHeight + 300) || isFetching) return;
        setIsFetching(true);
    }

    return [isFetching, setIsFetching];
};

export default useInfiniteScroll;