import React from 'react';
import 'react-slideshow-image/dist/styles.css';
import { IMAGE_URL } from "../../common/baseUrl";
import { Zoom } from 'react-slideshow-image';
import './slider.css';

const SliderForMobile = ({ images }) => {
  const slideImages = [
    IMAGE_URL + 'slider/' + images[0].mobile_slider_pic,
    IMAGE_URL + 'slider/' + images[1].mobile_slider_pic,
    IMAGE_URL + 'slider/' + images[2].mobile_slider_pic,
    IMAGE_URL + 'slider/' + images[3].mobile_slider_pic,
  ]

  return (
    <div className="slide-container">
      <Zoom scale={0.4}>
        {
          slideImages.map((each, index) => <img key={index} style={{ width: "100%" }} src={each} />)
        }
      </Zoom>     
    </div>
  );
};


export default SliderForMobile;