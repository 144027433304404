import { createSlice } from '@reduxjs/toolkit';

const appReducer = createSlice({
  name: 'app',
  initialState: {
    showFooter: true,
    startfatching: true,
    isOutletInfoFatched: false,
    allOutletInfo: [],
    outlet_id: 1,
    cityName: 'City Name',
    isOuletSelected: false,
    isAppStarted: true,
    loading: false,
    isLoaded: false,
    open: false,
    sliderInfo: [],
    product_type: [],
    specialCategories: [],
    sidebarData: [],
    popularItemsInfo: [],
    popularPageNo: 1,
    isPopularItemFetching: true,
    isAllPopularItemLoaded: false,
    subTypeInfo: [],
    subTypeData: [],
    orderInfo: [],
    typeId: 9900,
    subTypeId: 9900,
    deliveryChargeMax: "0",
    deliveryChargeMin: "0",
    mimimunAmount: "0",
    deliveryChargeMSG: "",
    deliveryNotice: "",
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isShowingSingleProduct: '0',
    paymentOption: '1',
    isCheckOut: false,
    verifyUsingOtp: '0'
  },
  reducers: {
    saveOutletInfo: (state, { payload }) => {
      state.startfatching = false;
      state.isOutletInfoFatched = true;
      state.allOutletInfo = payload.ouletInfo;
    },
    saveSelectedOutlet: (state, { payload }) => {
      console.log(payload);
      state.startfatching = true;
      state.isOutletInfoFatched = true;
      state.outlet_id = payload.outlet_id;
      state.cityName = payload.city;
      state.isOuletSelected = true;
    },
    saveAppsData: (state, action) => {
     // console.log('action.payload.charge_info[0]', action.payload.charge_info[0]);
      state.isLoaded = true;
      state.loading = true;
      state.startfatching = false;
      state.isOutletInfoFatched = true;
      state.product_type = action.payload.product_type;
      state.specialCategories = action.payload.specialCategories;
      state.popularItemsInfo = action.payload.popularItemsInfo;
      state.sliderInfo = action.payload.desktopSliderInfo;
      state.sidebarData = action.payload.menu_item;
      state.deliveryChargeMax = action.payload.charge_info[0].max_delivery_charge;
      state.deliveryChargeMin = action.payload.charge_info[0].min_delivery_charge;
      state.mimimunAmount = action.payload.charge_info[0].min_order_amount;
      state.deliveryChargeMSG = action.payload.charge_info[0].delivery_charge_notice;
      state.deliveryNotice = action.payload.charge_info[0].outlet_notice;
      state.verifyUsingOtp = action.payload.verifyUsingOtp
    },
    savePopularItemsInfo: (state, { payload }) => {
      state.popularItemsInfo = payload.popularItemsInfo;
      state.popularPageNo = 1;
      state.isPopularItemFetching = true;
      state.isAllPopularItemLoaded = false;
    },
    toggleOpen: (state) => {
      state.open = !state.open;
    },
    saveAppStatus: (state) => {
      if (!state.isLoaded) {
        state.isLoaded = false;
        state.loading = false;
        state.connectionLost = true;
      }
    },
    saveAppStartStatus: (state) => {
      state.isAppStarted = true;
    },
    changeCity: (state) => {
      state.startfatching = true;
      state.isOutletInfoFatched = false;
      state.outlet_id = 9999;
      state.cityName = 'City Name';
      state.isOuletSelected = false;
    },
    selectedType: (state, action) => {
      state.open = true;
      if (action.payload.filterSubType) {
        state.typeId = action.payload.type_id;
        const typeIdIndex = state.sidebarData.findIndex(
          (p) => p.type_id === action.payload.type_id
        );
        if (typeIdIndex > -1) {
          state.subTypeData = state.sidebarData[typeIdIndex].sub_menu;
        }
      }
      state.subTypeId = action.payload.subtype_id
      //state.subTypeInfo = state.sidebarData.filter(p => p.type_id === action.payload.type_id);
    },
    showWebFooter: (state) => {
      state.showFooter = true;
    },
    hideWebFooter: (state) => {
      state.showFooter = false;
    },
    startApp: (state) => {
      state.sliderInfo = [];
      state.sidebarData = [];
      state.popularItemsInfo = [];
      state.popularPageNo = 1;
      state.isPopularItemFetching = true;
      state.isAllPopularItemLoaded = false;
      state.orderInfo = [];
      state.typeId = "9900";
      state.deliveryChargeMax = "0";
      state.deliveryChargeMin = "0";
      state.mimimunAmount = "0";
      state.deliveryChargeMSG = "";
      state.deliveryNotice = "";
      state.isMobile = false;
      state.isTablet = false;
      state.isDesktop = false;
      state.isShowingSingleProduct = '0';
      state.paymentOption = '1';
      state.isCheckOut = false;
    },
  },
});

export const {
  saveOutletInfo,
  toggleOpen,
  saveSelectedOutlet,
  saveAppsData,
  savePopularItemsInfo,
  saveAppStatus,
  saveAppStartStatus,
  selectedType,
  changeCity,
  showWebFooter,
  hideWebFooter,
  startApp,
} = appReducer.actions;

export default appReducer.reducer;
