import React from 'react';
import { Drawer, Hidden } from '@mui/material';
import { useStyles } from '../../assets/styles/core.style';
import SidenavData from './SidenavData';
import '../../assets/css/layout//sidenav-sidenav-data.css';

export default function Sidenav({
  mobileOpen,
  handleDrawerOpen,
  handleDrawerClose,
}) {
  const { classes } = useStyles();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <SidenavData handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </Hidden>
      <Hidden lgDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <SidenavData handleDrawerClose={handleDrawerClose} />
        </Drawer>
      </Hidden>
    </nav>
  );
}
