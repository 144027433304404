import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { red } from '@mui/material/colors';
import emptyCart from '../../assets/images/empty-cart.jpg';
import '../../assets/css/layout/cart-drawer.css';
import { useCart } from '../../contexts/cart/use-cart';
import { IMAGE_URL } from '../../common/baseUrl';
import { CURRENCY } from '../../utils/constant';

const CartItems = () => {
  const { addItem, removeItem, removeItemFromCart, items, cartItemsCount } = useCart();

  return (
    <>
      <Box className="cart-content-sec">
        {cartItemsCount < 1 ? (
          <Box className="empty-cart">
            <img src={emptyCart} width="300" alt="empty-cart" />
            <Typography className="no-p-found-text">
              {' '}
              No product found
            </Typography>
          </Box>
        ) : (
          <Box className="cart-with-product">
            {items.map((cartItem) => (
              <>
                <Grid container key={cartItem.product_id}>
                  <Grid item xs={1} className="btn-sec-grid">
                    <Box className="cart-btn-sec">
                      {cartItem.max_allowed > 0 && cartItem.max_allowed <= cartItem.quantity ? (
                        <AddIcon className="cart-add-btn" sx={{ color: 'white' }} />
                      ) : (
                        <AddIcon className="cart-add-btn" onClick={() => addItem(cartItem)} />
                      )}
                      <input
                        defaultValue={cartItem.quantity}
                        type="number"
                        className="cart-input-btn"
                        value={cartItem.quantity}
                      />
                      <RemoveIcon className="cart-remove-btn" onClick={() => removeItem(cartItem)} />
                    </Box>
                  </Grid>
                  <Grid item xs={3} className="product-img-sec">
                    <Box>
                      {' '}
                      <img
                        src={
                          IMAGE_URL + cartItem.type_id + '/' + cartItem.web_pic1
                        }
                        width="90"
                        alt=""
                      />{' '}
                    </Box>
                  </Grid>
                  <Grid item xs={7} className="title-sec">
                    <Box className="title-sec-inner">
                      <Typography className="product-title">
                        {cartItem.product_title_eng} {cartItem.size}
                      </Typography>
                      <Box className="product-price-sec">
                        <Typography className="unit-price">
                          {CURRENCY} {cartItem.sale_price}
                        </Typography>
                        <Typography className="product-unit">
                          <ClearIcon />&nbsp;{'  '}{cartItem.quantity}
                        </Typography>
                      </Box>
                      <Typography className="total-price">
                        {CURRENCY} {cartItem.sale_price * cartItem.quantity}
                      </Typography>
                      {cartItem.delivery_charge > 0 && (
                        <Typography className="product-title">
                          Delivery Charge {CURRENCY} {parseFloat(parseFloat(cartItem.delivery_charge) * parseFloat(cartItem.quantity)).toFixed(2)}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={1} className="delete-sec">
                    <Box>
                      <DeleteForeverIcon color="primary" onClick={() => removeItemFromCart(cartItem)} />
                    </Box>
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: '#F7F7F7' }} />
              </>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default CartItems;
